import React from 'react';
import './App.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo from './blue_logo.png'
import transparentlogo from './white_transparent.png'
import thanks from './thanks_for.svg'
import self from './self-portrait.svg'
import nike from './Designs/airforce.jpg'
import backup from './Designs/backup.jpg'
import david from './Designs/david.jpg'
import destroyer from './Designs/destroyer-01.jpg'
import glue from './Designs/glue.jpg'
import koolaid from './Designs/koolaid.jpg'
import woman from './Designs/woman.jpg'
import greece from './Designs/greece-02.jpg'
import tiger from './Designs/tiger.jpg'
import rex from './Designs/rex.jpg'
import film from './Designs/portra.jpg'
import chucky from './Designs/rat.jpg'
import statue from './Designs/statue.jpg'
import designstitle from './Designs/designs_title.png'
import crunch from './Designs/crunch.jpg'
import captcha from './Designs/captcha.jpg'
import colosseum from './Designs/colosseum.jpg'
import shirtappui from './UI/shirtappui.png'
import plantappui from './UI/plantappux copy.png'
import artistui from './UI/artistui.png'
import readingui from './UI/readingui.png'
import hysteriawebui from './UI/hysteriawebui.png'
import spotifyui from './UI/spotifyui.png'
import uilayout from './UI/UILayout.png'
import photo1 from './Photos/DSCF0901.jpg'
import photo2 from './Photos/DSCF0907.jpg'
import photo3 from './Photos/DSCF1008.jpg'
import photo4 from './Photos/DSCF1012.jpg'
import photo5 from './Photos/DSCF1333.jpg'
import photo6 from './Photos/DSCF1823.jpg'
import photo7 from './Photos/DSCF2158.jpg'
import photo8 from './Photos/DSCF2256.jpg'
import photo9 from './Photos/DSCF2391.jpg'
import photo10 from './Photos/DSCF2679.jpg'
import photo11 from './Photos/DSCF2704.jpg'
import photo12 from './Photos/DSCF3292.jpg'
import photo13 from './Photos/DSCF3509.jpg'
import photo14 from './Photos/DSCF3788.jpg'
import photo15 from './Photos/DSCF3806.jpg'
import photo16 from './Photos/DSCF4145.jpg'


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};



class HomePage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
          <div className={"Page"}>
              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
                    integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
                    crossOrigin="anonymous" />
              <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;800&family=Playfair+Display:wght@900&display=swap" rel="stylesheet" />
              <link rel="stylesheet" href="https://use.typekit.net/gxm5igz.css" />

              <div className={"NavBar"}>
                  <a href ="https://lukasdoofus.github.io/project/"><img className="Logo" src={logo} /></a>
              </div>
              <h3 className = {"Big-Lukas"}>LUKAS</h3>
              <div className={"Blurb"}>
                  <h1 className={"Title"}>Lukas Viskanta</h1>
                  <h2 className={"Subtitle"}>Designer</h2>
                  <p className={"Description"}>Welcome to my portfolio! I am currently a product designer for <a href={"https://swoopsrch.com"}>Swoop</a> and
                      I graduated from UC Berkeley with a degree in Cognitive
                      Science and I am passionate about the intersection of design and the human mind.
                      I believe that equitable, modern, and effective design is essential in creating things that
                      people actually want to use. Reach out to me on Instagram if you are interested in collaborating!</p>
                  <div className={"Social-Icons"}>
                    <a href={"https://www.instagram.com/lukasdoofus/" } target="_blank"> <i className={"fab fa-instagram fa-2x"}
                          id = "instagram"/> </a>
                      <a href={"https://open.spotify.com/user/lbviskanta?si=-A06okj6TsuyzAOess0c5w"} target="_blank">
                          <i className={"fab fa-spotify fa-2x"} id = "spotify"/> </a>
                      <a href={"https://linkedin.com/in/lukasviskanta"} target="_blank">
                          <i className={"fab fa-linkedin fa-2x"} id = "linkedin"/> </a>
                  </div>
              </div>
              <img className={"Self"} src={self} />
              <img className={"Thanks-For"} src={thanks} />
              <h3 className= {"Big-Viskanta"}>VISKANTA</h3>
          </div>
        );
    }
}

class DesignsPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className={"Content"}>
                <img className={"Item1"} src={nike} style={{width: '100%'}}/>
                <img className={"Item2"} src={glue} style={{width: '100%'}}/>
                <img className={"Item3"} src={backup} style={{width: '100%'}}/>
                <img className={"Item4"} src={destroyer} style={{width: '100%'}}/>
                <img className={"Item5"} src={tiger} style={{width: '100%'}}/>
                <img className={"Item6"} src={chucky} style={{width: '100%'}}/>

                <img className={"Item7"} src={woman} style={{width: '100%'}}/>
                <img className={"Item8"} src={colosseum} style={{width: '100%'}}/>
                <h2 className={"DesignTitle"} style={{width: '100%'}}>Designs</h2>
                <img className={"Item9"} src={statue} style={{width: '100%'}}/>
                <img className={"Item10"} src={crunch} style={{width: '100%'}}/>

                <img className={"Item11"} src={greece} style={{width: '100%'}}/>
                <img className={"Item12"} src={david} style={{width: '100%'}}/>
                <img className={"Item13"} src={koolaid} style={{width: '100%'}}/>
                <img className={"Item14"} src={captcha} style={{width: '100%'}}/>
                <img className={"Item15"} src={film} style={{width: '100%'}}/>
                <img className={"Item16"} src={rex} style={{width: '100%'}}/>
            </div>
        );
    }
}

class UXPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <div>
                    <img className={"UILayout"} src={uilayout} style={{width: '100%'}}/>
                </div>


                <div className={"Content2"}>


                    <img className={"ele2"} src={spotifyui} style={{width: '75%'}} />
                    <img className={"ele3"} src={hysteriawebui} style={{width: '75%'}} />
                    <h2 className={"UITitle"} style={{width: '100%'}}>UI/UX</h2>
                    <img className={"ele4"} src={artistui} style={{width: '75%'}} />
                    <img className={"ele5"} src={readingui} style={{width: '75%'}} />
                    <img className={"ele6"} src={plantappui} style={{width: '75%'}} />

                </div>
            </div>
        );
    }
}

class PhotosPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div id="pf" className={"Content"}>
                <img className={"Item1"} src={photo1} style={{width: '100%'}}/>
                <img className={"Item2"} src={photo2} style={{width: '100%'}}/>
                <img className={"Item3"} src={photo3} style={{width: '100%'}}/>
                <img className={"Item4"} src={photo4} style={{width: '100%'}}/>
                <img className={"Item5"} src={photo5} style={{width: '100%'}}/>
                <img className={"Item6"} src={photo6} style={{width: '100%'}}/>

                <img className={"Item7"} src={photo7} style={{width: '100%'}}/>
                <img className={"Item8"} src={photo8} style={{width: '100%'}}/>
                <h2 className={"DesignTitle"} style={{width: '100%'}}>Photos</h2>
                <img className={"Item9"} src={photo9} style={{width: '100%'}}/>
                <img className={"Item10"} src={photo10} style={{width: '100%'}}/>

                <img id={"Photo11"} className={"Item11"} src={photo11} style={{width: '100%'}}/>
                <img className={"Item12 Photo12"} src={photo12} style={{width: '100%'}}/>
                <img className={"Item13 Photo13"} src={photo13} style={{width: '100%'}}/>
                <img className={"Item14 Photo14"} src={photo14} style={{width: '100%'}}/>
                <img className={"Item15 Photo15"} src={photo15} style={{width: '100%'}}/>
                <img className={"Item16 Photo16"} src={photo16} style={{width: '100%'}}/>
            </div>

        );
    }
}

class PlaylistPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"Playlist-Container"}>
                <iframe src="https://open.spotify.com/embed/playlist/2zxz3Xybo5gdXoduYM3TEA" width="30%" height="425"
                        frameBorder="0" allowTransparency="true" allow="encrypted-media" />
                <iframe src="https://open.spotify.com/embed/playlist/0Qh8aarX5WB2ZVWd7zZpac" width="30%" height="425"
                        frameBorder="0" allowTransparency="true" allow="encrypted-media" />
                <iframe src="https://open.spotify.com/embed/playlist/5MJHYDZe9ghCyDbLmmlGMX" width="30%" height="425"
                        frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
            </div>
        );
    }
}

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div className={"Footer"}>
                <div className={"SocialIcons"}>
                    <a href={"https://www.instagram.com/lukasdoofus/" } target="_blank">
                        <i className={"fab fa-instagram fa-2x"} />
                    </a>
                    <a href={"https://open.spotify.com/user/lbviskanta?si=-A06okj6TsuyzAOess0c5w"} target="_blank">
                        <i className={"fab fa-spotify fa-2x"} />
                    </a>
                    <a href={"https://linkedin.com/in/lukasviskanta"} target="_blank">
                        <i className={"fab fa-linkedin fa-2x"} />
                    </a>
                </div>
                <img className={"Transparentlogo"} src={transparentlogo}/>
                <p className={"Copyright"}>Copyright © 2018 LukasDoofus. All rights reserved.</p>
            </div>
        );
    }
}

{/*Use App Component to Nest All Others*/}
class App extends React.Component {
  constructor(props) {
    super(props);
}
  render(){
    return(
        <div className={"Container"}>
            <HomePage />
            <DesignsPage />
            <UXPage />
            <PhotosPage />
            <Footer />
        </div>
    )
  }
}

export default App;
